import React from 'react'
import { createStyles, makeStyles, Theme, fade } from '@material-ui/core/styles'
import MButton, { ButtonProps as MButtonProps } from '@material-ui/core/Button'

const useButtonStyles = makeStyles(
  (theme: Theme) => {
    const styles: any = {
      root: {
        destructive: {
          'color': theme.palette.error.main,
          '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.04),
          },
        },
        white: {
          'color': theme.palette.primary.contrastText,
          '&:hover': {
            backgroundColor: fade(theme.palette.primary.contrastText, 0.04),
          },
        },
      },
      outlined: {
        destructive: {
          border: `1px solid ${
            theme.palette.type === 'light'
              ? theme.palette.error.main
              : 'rgba(255, 255, 255, 0.23)' // Will change with alt theme
          }`,
        },
        white: {
          border: `1px solid ${
            theme.palette.type === 'light'
              ? theme.palette.primary.contrastText
              : 'rgba(255, 255, 255, 0.23)' // Will change with alt theme
          }`,
        },
      },
      contained: {
        destructive: {
          'color': theme.palette.error.contrastText,
          'backgroundColor': theme.palette.error.main,
          '&:hover': {
            'backgroundColor': theme.palette.error.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: theme.palette.error.main,
            },
          },
        },
        white: {
          'color': theme.palette.primary.main,
          'backgroundColor': theme.palette.primary.contrastText,
          '&:hover': {
            'backgroundColor': fade(theme.palette.primary.contrastText, 0.9),
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: theme.palette.primary.contrastText,
            },
          },
        },
      },
    }

    return createStyles({
      /* Styles applied to the root element. */
      root: (props: ButtonProps) => ({
        ...styles.root[props.color!],
        ...(props.classes?.root as {}),
      }),
      /* Styles applied to the root element if `variant="outlined"`. */
      outlined: (props: ButtonProps) => ({
        ...styles.outlined[props.color!],
        ...(props.classes?.outlined as {}),
      }),
      /* Styles applied to the root element if `variant="contained"`. */
      contained: (props: ButtonProps) => ({
        ...styles.contained[props.color!],
        ...(props.classes?.contained as {}),
      }),
    })
  },
  // The root-name of the classes generated:
  { name: 'MuiAscensionButton' },
)

export interface ButtonProps<C = React.ElementType>
  extends Omit<MButtonProps, 'color'> {
  component?: C
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'default'
    | 'destructive'
    | 'white'
}
/**
 * Ascension Brand Button - with two additional color options
 * @param props
 *
 * `color="destructive"` renders a error-themed variant of the button
 * `color="white"` renders a white-themed variant of the button
 *
 * Notes: destructive and white will consume the classes object v.0.2.1
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(props, ref) {
    const { classes, color, ...rest } = props
    const styles = useButtonStyles(props)

    if (color === 'destructive' || color === 'white')
      return <MButton ref={ref} classes={styles} {...rest} />

    return <MButton ref={ref} color={color} classes={classes} {...rest} />
  },
)
